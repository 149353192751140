import { MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

@Component({
  selector: 'app-sconto-dialog',
  templateUrl: './sconto-dialog.component.html',
  styleUrls: ['./sconto-dialog.component.css']
})
export class ScontoDialogComponent {
  sconto: string = '';
  title: string;
  importo: number;

  constructor(
    public dialogRef: MatDialogRef<ScontoDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: ScontoDialogModel
  ) {
    // Update view with given values
    this.title = data.title;
    this.importo = data.importo;
  }

  onConfirm(): void {
    // Close the dialog, return true
    if (this.validaImporto) {
      this.dialogRef.close(this.sconto);
    }
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  // VARIE
  digit(val: string) {
    let i = this.sconto.indexOf('.');
    let dim = this.sconto.length;
    if (!(val == '.' && i != -1) && dim < 7 && (i == -1 || i > dim - 3)) {
      this.sconto = this.sconto.concat(val);
    }
  }
  clear(n) {
    this.sconto = n ? this.sconto.substr(0, this.sconto.length - n) : '';
  }
  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^[\d.]*(?:[.,]\d{1,2})?$/;
    let i = this.sconto.indexOf('.');
    let dim = this.sconto.length;

    if (
      !(
        reg.test(input) &&
        (!(input == '.' && i != -1) && dim < 7 && (i == -1 || i > dim - 3))
      )
    ) {
      e.preventDefault();
    }
  }
  validaImporto() {
    return +this.sconto <= this.importo;
  }
}



export class ScontoDialogModel {
  constructor(
    public title: string,
    public importo: number
  ) { }
}
