import { LogoutUtility } from './../app-login/logout-utility';
import { ChangeDetectionStrategy } from '@angular/core';
import { FirebaseService } from './../services/firebase.service';
import { BeService } from './../services/be.service';
import { Tavolo, Risto } from './../interface/risto.interface';
import { TranslateService } from '@ngx-translate/core';
import { TavoloDialogModel, TavoloDialogComponent } from './../util/tavolo-dialog/tavolo-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-risto',
  templateUrl: './risto.component.html',
  styleUrls: ['./risto.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class RistoComponent implements OnInit {
  currentLang: string;
  risto: Risto;
  tavoli: Tavolo[];
  listnerTavoli: any;
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private dialog: MatDialog,
    public translate: TranslateService,
    public beService: BeService,
    public firebaseService: FirebaseService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    });
    this.beService.currentRistoDetail.subscribe(res => {
      if (res.id) {
        this.risto = res;
        this.getTavoli();
      }
    });
    this.firebaseService.listnerTavoli().subscribe(res => {
      this.listnerTavoli = res;
      if (this.risto) { this.getTavoli(); }

    });
  }

  getTavoli() {
    this.beService.getListTavoli(+this.risto.id).then(tav => {
      this.tavoli = tav;
    });
  }

  addTavolo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = new TavoloDialogModel(
      "Aggiungi nuovo Tavolo",
      "Nome Tavolo"
    );

    const dialogRef = this.dialog.open(TavoloDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (this.risto) {
          const tavolo = {} as Tavolo;
          tavolo.idRistorante = +this.risto.id;
          tavolo.nome = dialogResult;
          this.beService.addTavolo(tavolo).then(() => this.getTavoli());
        }
      }
    });
  }

  tavDettaglio(tav: Tavolo) {
    if (tav.coperti > 0) {
      this.router.navigateByUrl("/tavolo/" + tav.id);
    }
  }

  logout() {
    const u = new LogoutUtility(this.router, this.afAuth, this.dialog);
    u.logout();
  }
}
