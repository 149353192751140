import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Injectable } from '@angular/core';

@Injectable()
export class PageTitleService {
  public title: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  setTitle(value: string) {
    this.title.next(value);
  }
}
