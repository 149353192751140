import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {OrdineStorico, OrdineStoricoReq} from '../interface/risto.interface';
import {BeService} from '../services/be.service';
import * as _moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-storico',
  templateUrl: './storico.component.html',
  styleUrls: ['./storico.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class StoricoComponent {
  dataFormat = 'dd/MM/yyyy HH:mm';
  displayedColumns = ['data', 'coperti', 'totale'];
  data: OrdineStorico[];
  dataSource;
  expandedElement: any;
  toData = _moment();
  fromData = _moment();
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  constructor(public beService: BeService) {
    this.beService.storico(this.getOrdStoricoReq(_moment(), _moment())).then(res => {
      this.dataSource = new StoricoDataSource(res);
    });
  }

  private getStringFromData(today: Date): string {
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }
  totaleportate(ordine: OrdineStorico) {
    let portate = 0;
    ordine.ordineDettaglios.forEach(dett => portate += +dett.quantita);
    return portate;
  }

  cercaStorico() {
    this.beService.storico(this.getOrdStoricoReq(this.fromData, this.toData)).then(res => {
      this.dataSource = new StoricoDataSource(res);
    });
  }
  private getOrdStoricoReq(fromData, toData): OrdineStoricoReq {
  const ordStoricoReq = {} as OrdineStoricoReq;
  ordStoricoReq.idRistorante = 1;
  ordStoricoReq.dataFrom = fromData.format('YYYY-MM-DD');
  ordStoricoReq.dataTo =  toData.add(1, 'd').format('YYYY-MM-DD');
  return ordStoricoReq;
}

}

export class StoricoDataSource extends DataSource<any> {
  data: any;
  constructor(indata) {
    super();
    this.data = indata;
  }
  connect(): Observable<OrdineStorico[]> {
    const rows = [];
    this.data.forEach(element => rows.push(element, { detailRow: true, element }));
    return of(rows);
  }

  disconnect() { }
}
