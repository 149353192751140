import { Portate } from './../../interface/menu.interface';
import { BeService } from './../../services/be.service';
import { FormControl } from '@angular/forms';
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatAutocompleteSelectedEvent } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {User} from '../../interface/user.interface';

@Component({
  selector: "app-menu-dialog",
  templateUrl: "./menu-dialog.component.html",
  styleUrls: ["./menu-dialog.component.css"]
})

export class MenuDialogComponent {
  user: User;
  isChef: boolean;
  title: string;
  message1: string;
  currentLang: string;
  idRisto: number;
  idCategoria: number;
  listPortateInput = new Array<Portate>();
  tablePortate: MatTableDataSource<Portate>;
  myControl = new FormControl();
  options: Portate[];
  filteredOptions: Observable<Portate[]>;
  selectedIngrediente: any;
  portataAdd: any;

  constructor(
    public beService: BeService,
    public dialogRef: MatDialogRef<MenuDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: MenuDialogModel,
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    });
    // Update view with given values
    this.title = data.title;
    this.message1 = data.message1;
    this.idRisto = data.idRisto;
    this.idCategoria = data.idCategoria;
    // Ingredienti
    this.getPortate();
    this.initUser();
  }

  private initUser() {
    this.user = this.beService.getUser();
    this.isChef = this.user.authority === 'ROLE_CHEF';
  }

  private getPortate() {
    if (this.idCategoria === 0) {
      this.beService.getCatMenuRistoranteEnable(this.idRisto).then(res => {
        this.populateDialog(res);
      });
    } else {
      this.beService.getCatMenuById(this.idCategoria).then(catId => {
        const listCat = new Array();
        listCat.push(catId);
        this.populateDialog(listCat);
      });
    }

  }

  private populateDialog(res: any[]) {
    res.forEach(cat => {
      if (cat.pietanzas) {
        cat.pietanzas.forEach(piet => {
          if (piet.abilitato) {
            this.listPortateInput.push(piet);
          }
        });
      }
    });
    this.options = this.listPortateInput;
    // tslint:disable-next-line:max-line-length
    this.filteredOptions = this.myControl.valueChanges.pipe(startWith(''), map(value => typeof value === 'string' ? value : value.titolo), map(name => (name ? this._filter(name) : this.options.slice())));
  }

  private _filter(name: string): Portate[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(
      option =>
        option.titolo.toLowerCase().includes(filterValue));
  }


  displayFn(portate?: Portate): string | undefined {
    return portate ? portate.titolo : undefined;
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.portataAdd = event.option.value;
    this.portataAdd.quantita = 1;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(this.portataAdd);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

export class MenuDialogModel {
  constructor(
    public title: string,
    public message1: string,
    public idRisto: number,
    public idCategoria: number
  ) { }
}
