import { AngularFireAuth } from 'angularfire2/auth';
import { CustomValidators } from 'ng2-validation';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-session',
  templateUrl: './login-component.html',
  styleUrls: ['./login-component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  state: '';
  error: any;
  public form: FormGroup;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private fb: FormBuilder,
    public translate: TranslateService
  ) {
    const browserLang: string = translate.getBrowserLang();
    const lang = browserLang.match(/en|it|fr/) ? browserLang : 'it';
    translate.setDefaultLang('it');
    translate.use(lang);
    this.afAuth.authState.subscribe(auth => {
      if (auth) {
        this.router.navigate(['/home']);
      }
    });
  }
  ngOnInit() {
    this.form = this.fb.group({
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email])
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(6)])
      ]
    });
  }

  async onSubmit() {
    if (this.form.valid) {
      try {
        const success = await this.afAuth.auth
          .signInWithEmailAndPassword(this.form.value.email, this.form.value.password);
        this.router.navigate(['/home']);
      }
      catch (err) {
        this.error = err;
      }
    }
  }
}
