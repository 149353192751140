import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Observable } from 'rxjs';

@Injectable()
export class FirebaseService {
  constructor(private db: AngularFireDatabase) { }

  listnerTavoli(): Observable<any> {
    return this.db.list('/hytraining', ref => ref.orderByKey()).snapshotChanges().map(changes => {
      return changes.map(c => ({ key: c.payload.key, value: c.payload.val() }));
    });
  }
  monitorOrdini(): Observable<any> {
    return this.db.list('/hytraining', ref => ref.orderByKey()).stateChanges();
  }
  monitorOrdineTavolo(nomeTavolo: string): Observable<any> {
    return this.db.object('/hytraining/' + nomeTavolo).valueChanges();
  }
}
