import { Component, OnInit } from '@angular/core';
import {Ordine, OrdineDettaglio, Risto} from '../interface/risto.interface';
import {Router} from '@angular/router';
import {BeService} from '../services/be.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {MenuDialogComponent, MenuDialogModel} from '../util/menu-dialog/menu-dialog.component';
import {Portate} from '../interface/menu.interface';
import {MagazzinoUtility} from '../magazzino-utility';

@Component({
  selector: 'app-semilavorati',
  templateUrl: './semilavorati.component.html',
  styleUrls: ['./semilavorati.component.css']
})
export class SemilavoratiComponent implements OnInit {
  risto: Risto;
  ordini = {} as Ordine;
  constructor(private router: Router, public beService: BeService,
              public translate: TranslateService,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.beService.currentRistoDetail.subscribe(res => {
      if (res.id) {
        this.risto = res;
      }
    });

  }

  addOrderList(idRistorante: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = new MenuDialogModel(
      'Aggiungi Prodotto da scaricare',
      'Cerca prodotto', +idRistorante, 21
    );

    const dialogRef = this.dialog.open(MenuDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.addPortata(dialogResult);
      }
    });

  }
  private addPortata(portata) {
    if (!this.ordini.ordineDettaglios) {
      this.ordini.ordineDettaglios = new Array<OrdineDettaglio>();
    }
    const ordineDettaglio = {} as OrdineDettaglio;
    ordineDettaglio.pietanza = portata;
    ordineDettaglio.quantita = portata.quantita;
    ordineDettaglio.prezzo = 0;
    this.ordini.ordineDettaglios.push(ordineDettaglio);

  }

  modQuantita(ord: OrdineDettaglio, op: string) {
    ord.quantita = op === 'D' ? ord.quantita - 1 : ord.quantita + 1;
  }

  caricaMagazino() {
    const scaricoMagazzino = MagazzinoUtility.scaricoMagazzino(+this.risto.id, 27, 'N', 'X', this.ordini.ordineDettaglios, 0, 0);
    this.beService.ingredientiSemiLavorato(scaricoMagazzino).then(() => {
      this.beService.stampaCaricoMagazzino(this.ordini);
      this.router.navigate(['/monitor']);
    });
  }
}
