import { EntryMenuComponent } from './entry-menu/entry-menu.component';
import { ListMenuComponent } from './list-menu/list-menu.component';
import { MenuDialogComponent } from './util/menu-dialog/menu-dialog.component';
import { FirebaseService } from './services/firebase.service';
import { CustomHttpInterceptor } from './services/http.interceptor';
import { ResetPasswordComponent } from './app-login/reset-password/reset-password.component';
import { LoginComponent } from './app-login/login/login.component';
import { AuthGuard } from './services/auth.service';
import { PageTitleService } from './services/page-title.service';
import { AppRoutes } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from './../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyNavComponent } from './my-nav/my-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatDialogModule,
  MatExpansionModule,
  MatTableModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatStepperModule,
  MatRadioModule,
  MatSelectModule,
  MatTabsModule, MatDatepickerModule, MatNativeDateModule
} from '@angular/material';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from './util/confirm-dialog/confirm-dialog.component';
import { GoogleTranslateService } from './services/google-translate.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BeService } from './services/be.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxGalleryModule } from 'ngx-gallery';
import { HomeComponent } from './home/home.component';
import { CassaComponent } from './cassa/cassa.component';
import { MonitorComponent } from './monitor/monitor.component';
import { RistoComponent } from './risto/risto.component';
import { TavoloComponent } from './tavolo/tavolo.component';
import { TavoloDialogComponent } from './util/tavolo-dialog/tavolo-dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScontoDialogComponent } from './util/sconto-dialog/sconto-dialog.component';
import { ScaricoMagazzinoComponent } from './scarico-magazzino/scarico-magazzino.component';
import { SemilavoratiComponent } from './semilavorati/semilavorati.component';
import { StoricoComponent } from './storico/storico.component';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    MyNavComponent,
    LoginComponent,
    ResetPasswordComponent,
    ConfirmDialogComponent,
    TavoloDialogComponent,
    EntryMenuComponent,
    HomeComponent,
    CassaComponent,
    MonitorComponent,
    RistoComponent,
    TavoloComponent,
    MenuDialogComponent,
    ListMenuComponent,
    ScontoDialogComponent,
    ScaricoMagazzinoComponent,
    SemilavoratiComponent,
    StoricoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes),
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatTabsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(
      environment.firebase,
      'angular-auth-firebase'
    ),
    //    AgmCoreModule.forRoot({
    //      apiKey: 'AIzaSyArCAnZu8dDC1jWAzXT5jQWpgTk_HJhjFM', // AIzaSyCbf443k4XAw7xWPHfmKwU3soPcPWulgFk'
    //      libraries: ['places']
    //    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    MatDialogModule,
    MatExpansionModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ImageCropperModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule,
    NgxSpinnerModule,
    NgxGalleryModule,
    ScrollingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    PageTitleService,
    AuthGuard,
    BeService,
    TranslateService,
    //  GoogleTranslateService,
    FirebaseService
  ],
  entryComponents: [
    ConfirmDialogComponent,
    TavoloDialogComponent,
    MenuDialogComponent,
    EntryMenuComponent,
    ScontoDialogComponent
    /*,
    AllargeniPopupComponent,
    ImageEditorComponent,
    OrderDetailComponent*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
