import { Router } from '@angular/router';
import { MagazzinoUtility } from './../magazzino-utility';
import { Portate } from './../interface/menu.interface';
import { OrdineDettaglio, OrdineStampa, OrdineDettaglioStampa } from './../interface/risto.interface';
import { MenuDialogModel, MenuDialogComponent } from './../util/menu-dialog/menu-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { BeService } from 'src/app/services/be.service';
import { Component, OnInit } from '@angular/core';
import { Risto, Ordine } from '../interface/risto.interface';

@Component({
  selector: 'app-scarico-magazzino',
  templateUrl: './scarico-magazzino.component.html',
  styleUrls: ['./scarico-magazzino.component.css']
})
export class ScaricoMagazzinoComponent implements OnInit {
  risto: Risto;
  ordini = {} as Ordine;
  constructor(private router: Router, public beService: BeService,
    public translate: TranslateService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.beService.currentRistoDetail.subscribe(res => {
      if (res.id) {
        this.risto = res;
      }
    });

  }

  addOrderList(idRistorante: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = new MenuDialogModel(
      'Aggiungi Prodotto da scaricare',
      'Cerca prodotto', +idRistorante, 8
    );

    const dialogRef = this.dialog.open(MenuDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.addPortata(dialogResult);
      }
    });

  }
  private addPortata(portata: Portate) {
    if (!this.ordini.ordineDettaglios) {
      this.ordini.ordineDettaglios = new Array<OrdineDettaglio>();
    }
    const ordineDettaglio = {} as OrdineDettaglio;
    ordineDettaglio.pietanza = portata;
    ordineDettaglio.quantita = 1;
    ordineDettaglio.prezzo = portata.prezzo;
    this.ordini.ordineDettaglios.push(ordineDettaglio);

  }

  modQuantita(ord: OrdineDettaglio, op: string) {
    ord.quantita = op === 'D' ? ord.quantita - 1 : ord.quantita + 1;
  }

  caricaMagazino() {
    const scaricoMagazzino = MagazzinoUtility.scaricoMagazzino(+this.risto.id, 25, 'N', 'X', this.ordini.ordineDettaglios, 0, 0);
    this.beService.caricoMagazzinoBar(scaricoMagazzino).then(() => {
      this.beService.stampaCaricoMagazzino(this.ordini);
      this.router.navigate(['/monitor']);
    });
  }
}
