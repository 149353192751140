export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA7UnxNiLnR7JpNlhJKtghWG5YtU_KA1qk',
    authDomain: 'appsala-8f228.firebaseapp.com',
    databaseURL: 'https://appsala-8f228.firebaseio.com',
    projectId: 'appsala-8f228',
    storageBucket: 'appsala-8f228.appspot.com',
    messagingSenderId: '698593889418',
    appId: '1:698593889418:web:a22d57954eb4c38e8ef173',
    measurementId: 'G-QTDF98GSMZ'
  },
  urlBe: 'https://api.hosteriahoney.it/app-sala',
  urlRisto: 'http://localhost:8081',
  urlCucina: 'http://localhost:8081',
  urlBar: 'http://localhost:8081',
  stampaRisto: 'RISTORANTE',
  stampaCucina: 'CUCINA',
  indaco: 'https://api.hosteriahoney.it/hsc/rest'

};
