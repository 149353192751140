import { LogoutUtility } from './../app-login/logout-utility';
import { Risto, TavoloMonitor, OrdineDettaglio, ComandaMonitor, PietanzaProntaReq, Tavolo } from './../interface/risto.interface';
import { FirebaseService } from './../services/firebase.service';
import { MatDialog } from '@angular/material';
import { AngularFireAuth } from 'angularfire2/auth';
import { BeService } from './../services/be.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MonitorComponent implements OnInit {
  currentLang: string;
  listnerTavoli: any;
  risto: Risto;
  monitor = new Array<TavoloMonitor>();
  selTavolo: TavoloMonitor;
  constructor(
    private router: Router,
    public translate: TranslateService,
    public beService: BeService,
    private afAuth: AngularFireAuth,
    private dialog: MatDialog,
    public firebaseService: FirebaseService
  ) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    });
    this.beService.currentRistoDetail.subscribe(res => {
      if (res.id) {
        this.risto = res;

        this.firebaseService.monitorOrdini().subscribe(lis => {
          const nomeTavolo = lis.key;
          const numeroComande = lis.payload.val();

          switch (lis.type) {
            case 'child_added':
              if (numeroComande > 0) {
                this.getTavoloMonitor(nomeTavolo, numeroComande).then(tav => this.monitor.push(tav));
              }
              break;
            case 'child_changed':
              const index = this.getIndexTavoloMonitor(nomeTavolo);
              if (numeroComande > 0) {
                if (index !== -1) {
                  this.getPortate(this.monitor[index], numeroComande);
                } else {
                  this.getTavoloMonitor(nomeTavolo, numeroComande).then(tav => (this.monitor.push(tav)));
                }
              } else {
                if (index !== -1) {
                  this.monitor.splice(index, 1);
                  this.selTavolo = undefined;
                }
              }
              break;
            case 'child_removed':
              this.monitor.splice(this.getIndexTavoloMonitor(nomeTavolo), 1);
              break;
          }
        });

      }
    });

  }
  getPortate(monitorTavolo: TavoloMonitor, numeroPortate: number) {
    this.getOrdineTavolo(monitorTavolo, numeroPortate);
  }
  getIndexTavoloMonitor(nomeTavolo: string): number {
    return this.monitor.findIndex(item => item.tavolo.nome === nomeTavolo);
  }
  async getTavoloMonitor(nomeTavolo: string, numeroComande: number): Promise<TavoloMonitor> {
    const tavoloM = {} as TavoloMonitor;

    const promise = new Array<any>();
    promise.push(this.beService.getTavoloByNome(+this.risto.id, nomeTavolo).then(tavolo => {
      tavoloM.tavolo = tavolo;
      tavoloM.tavolo.numeroComande = numeroComande;
      this.beService.getOrdiniMonitorByTavolo(+this.risto.id, tavolo.id).then(ordini => {
        if (ordini.length > 0) {
          tavoloM.idOrdine = ordini[0].id;
          tavoloM.comande = this.setComandeByUscita(tavoloM, ordini[0].ordineDettaglios);
        }
      });
    }));
    return await Promise.all(promise).then(() => {
      return tavoloM;
    });
  }

  getOrdineTavolo(tavolo: TavoloMonitor, numeroComande: number) {
    this.beService.getOrdiniMonitorByTavolo(+this.risto.id, tavolo.tavolo.id).then(ordini => {
      if (ordini.length > 0) {
        tavolo.idOrdine = ordini[0].id;
        tavolo.comande = this.setComandeByUscita(tavolo, ordini[0].ordineDettaglios);
        tavolo.tavolo.numeroComande = numeroComande;
      }
    });
    if (tavolo.tavolo.numeroComande === 0) {
      const index = this.getIndexTavoloMonitor(tavolo.tavolo.nome);
      this.monitor.splice(index, 1);
    }
  }
  setComandeByUscita(tavolo: TavoloMonitor, ordineDettaglios: OrdineDettaglio[]): ComandaMonitor[] {
    const comMon = new Array<ComandaMonitor>();
    ordineDettaglios.sort((a, b) => a.uscita - b.uscita);
    ordineDettaglios.forEach(dett => {
       if (!comMon[dett.uscita - 1]) {
        comMon.push({} as ComandaMonitor);
        comMon[dett.uscita - 1].ordini = new Array<OrdineDettaglio>();
        comMon[dett.uscita - 1].numDaEvadere = 0;
        comMon[dett.uscita - 1].show = false;
      }
      comMon[dett.uscita - 1].ordini.push(dett);
      if (!dett.pronta && dett.pietanza.monitor) {
        comMon[dett.uscita - 1].numDaEvadere += dett.quantita;
        comMon[dett.uscita - 1].show = true;
      }
    });
    return comMon;
  }

  prontaUscita(selTavolo: TavoloMonitor, uscitaIndex: number) {
    console.log('SEL_TAVOLO', selTavolo);
    const pietanzaProntaReq = {} as PietanzaProntaReq;
    pietanzaProntaReq.nomeTavolo = selTavolo.tavolo.nome;
    pietanzaProntaReq.idOrdine = selTavolo.idOrdine;
    pietanzaProntaReq.uscita = uscitaIndex + 1;
    this.beService.setPietanzaPronta(pietanzaProntaReq);
  }

  prontaPortata(selTavolo: TavoloMonitor, uscitaIndex: number, ordineIndex: number) {
  /*  const pietanzaProntaReq = {} as PietanzaProntaReq;
    pietanzaProntaReq.nomeTavolo = selTavolo.tavolo.nome;
    pietanzaProntaReq.idOrdineDettaglio = selTavolo.comande[uscitaIndex].ordini[ordineIndex].id;
    this.beService.setPietanzaPronta(pietanzaProntaReq).then(() => {
      if (selTavolo.comande[uscitaIndex].numDaEvadere === 1) {
        const index = this.getIndexTavoloMonitor(selTavolo.tavolo.nome);
        this.monitor.splice(index, 1);
        if (selTavolo.tavolo.numeroComande - 1 > 0) {
          this.monitor.push(selTavolo);
        }
        this.selTavolo = undefined;
      }
    });*/
  }

  logout() {
    const u = new LogoutUtility(this.router, this.afAuth, this.dialog);
    u.logout();
  }

}
