import {
  MatAutocompleteSelectedEvent,
  MatRadioChange,
  MatDialogConfig,
  MatStepper
} from '@angular/material';
import {
  Ingredienti,
  EditMenu,
  CategoriaMenu,
  IngredientePortata,
  Supplemento,
  Portate
} from './../interface/menu.interface';
import { BeService } from 'src/app/services/be.service';
import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { MatDialog } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {OrdineDettaglio} from '../interface/risto.interface';
import {UnitaMisura} from '../interface/indaco.interface';


@Component({
  selector: 'app-entry-menu',
  templateUrl: './entry-menu.component.html',
  styleUrls: ['./entry-menu.component.css']
})
export class EntryMenuComponent implements OnInit {
  @Input() editMenu: EditMenu;
  @Output() updMenu = new EventEmitter<number>();
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  islinear = true;
  editable = true;
  selectRadio: string;
  tipPieFormGroup: FormGroup;
  anagPieFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  ingControl = new FormControl();
  supControl = new FormControl();
  currentLang: string;
  sub: any;
  idRisto: any;
  listCategoriaMenu: CategoriaMenu[];
  options: Ingredienti[];
  filteredOptions: Observable<Ingredienti[]>;
  filteredSupOptions: Observable<Ingredienti[]>;
  tableIngredienti = new Array<IngredientePortata>();
  tableSupplementi = new Array<Supplemento>();
  img: File;
  url: any;
  unitaMisura: UnitaMisura[];
  numeroStep = 1;
  optionSel: string;

  constructor(
    private _formBuilder: FormBuilder,
    public translate: TranslateService,
    public dialog: MatDialog,
    public beService: BeService
  ) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    });
    // FORM GROUP
    this.anagPieFormGroup = this._formBuilder.group({
      nome_pie: ['', Validators.required],
      des_pie: ['', Validators.required],
      cat_pie: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({});
    this.fourthFormGroup = this._formBuilder.group({});
    this.beService.unitaMisura().then(res => this.unitaMisura = res);
    if (this.editMenu) {
      // Ingredienti
      this.beService.getListIngredienti(this.editMenu.idRisto).then(res => {
        this.options = res;
        this.filteredOptions = this.ingControl.valueChanges.pipe(
          startWith(''),
          map(value =>
            typeof value === 'string'
              ? value
              : value
                ? value.ingrediente
                : ''
          ),
          map(name => (name ? this._filter(name) : this.options.slice()))
        );
        this.filteredSupOptions = this.supControl.valueChanges.pipe(
          startWith(''),
          map(value =>
            typeof value === 'string'
              ? value
              : value
                ? value.ingrediente
                : ''
          ),
          map(name => (name ? this._filter(name) : this.options.slice()))
        );
      });

      this.listCategoriaMenu = this.editMenu.categorieMenu;
      if (!this.editMenu.portata) {
        this.editMenu.portata = {} as Portate;
      }
      if (this.editMenu.portata.idCategoriaMenu) {
        this.anagPieFormGroup.patchValue({
          cat_pie: this.editMenu.portata.idCategoriaMenu
        });
      }
      if (this.editMenu.portata.titolo) {
        this.anagPieFormGroup.patchValue({
          nome_pie: this.editMenu.portata.titolo
        });
      }
      if (this.editMenu.portata.descrizione) {
        this.anagPieFormGroup.patchValue({
          des_pie: this.editMenu.portata.descrizione
        });
      }
      /*if (this.editMenu.portata.unitaMisuraCarico) {
        this.anagPieFormGroup.patchValue({
          umc: this.editMenu.portata.unitaMisuraCarico
        });
      }
      if (this.editMenu.portata.unitaMisuraScarico) {
        this.anagPieFormGroup.patchValue({
          ums: this.editMenu.portata.unitaMisuraScarico
        });
      }
*/
      if (this.editMenu.portata.pietanzaIngredientes) {
        this.tableIngredienti = this.editMenu.portata.pietanzaIngredientes;
        this.tableIngredienti.forEach(ing => {
          ing.idIngrediente = ing.ingrediente.id;
        });
      }
      if (this.editMenu.portata.pietanzaSupplementos) {
        this.tableSupplementi = this.editMenu.portata.pietanzaSupplementos;
        this.tableSupplementi.forEach(sup => {
          sup.idIngrediente = sup.ingrediente.id;
        });
      }
      if (this.editMenu.portata.immagine) {
        this.url = this.editMenu.portata.immagine;
      }
    }
  }
  private _filter(name: string): Ingredienti[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(
      option =>
        option.ingrediente.toLowerCase().includes(filterValue));
  }

  displayFn(ing: Ingredienti): string {
    return '';
  }

  onSelectionIngChanged(event: MatAutocompleteSelectedEvent) {
    let sel: Ingredienti;
    sel = event.option.value;
    const ingPortata = {} as IngredientePortata;
    ingPortata.id = 0;
    ingPortata.idIngrediente = sel.id;
    ingPortata.ingrediente = sel;
    ingPortata.obbligatorio = true;
    ingPortata.quantita = 1;
    this.tableIngredienti.push(ingPortata);
    this.ingControl.reset();
  }

  onSelectionSupChanged(event: MatAutocompleteSelectedEvent) {
    let sel: Ingredienti;
    sel = event.option.value;
    const supPortata = {} as Supplemento;
    supPortata.id = 0;
    supPortata.idIngrediente = sel.id;
    supPortata.ingrediente = sel;
    supPortata.prezzo = 0;
    supPortata.quantita = 1;
    this.tableSupplementi.push(supPortata);
    this.supControl.reset();
  }
  setCategoria() {
    this.editMenu.portata.idCategoriaMenu = this.anagPieFormGroup.controls.cat_pie.value;
  }
  setUnitaMisuraCarico() {
    this.editMenu.portata.unitaMisuraCarico = this.anagPieFormGroup.controls.umc.value;
  }
  setUnitaMisuraScarico() {
    this.editMenu.portata.unitaMisuraScarico = this.anagPieFormGroup.controls.ums.value;
  }
  checkMonitor(ev: any, portata: Portate) {
    portata.monitor = ev.target.checked;
  }
  stepAnag() {
    this.editMenu.portata.titolo = this.anagPieFormGroup.controls.nome_pie.value;
    this.editMenu.portata.descrizione = this.anagPieFormGroup.controls.des_pie.value;
    if (this.editMenu.portata.idCategoriaMenu === 21) {
      this.editMenu.portata.prezzo = 0;
      this.editMenu.portata.monitor = false;
    }
  }
  stepIng() {
    this.editMenu.portata.pietanzaIngredientes = this.tableIngredienti;
  }
  stepSup() {
    this.editMenu.portata.pietanzaSupplementos = this.tableSupplementi;
    this.salva();
  }
  deleteIng(index: number) {
    this.tableIngredienti.splice(index, 1);
  }
  deleteSup(index: number) {
    this.tableSupplementi.splice(index, 1);
  }
  salvaRapido() {
    this.stepIng();
    this.stepSup();
  }
  salva() {
    // SALVA IMMAGINE
    if (this.img) {
      this.beService
        .addPhotoPietanza(this.img, String(this.editMenu.idRisto))
        .then(res => {
          this.editMenu.portata.immagine = res.fileDownloadUri;
          this.salvaPortata();
        });
    } else {
      this.salvaPortata();
    }
  }

  salvaPortata() {
    if (!this.editMenu.portata.codFornitore) {
      const timeStamp = new Date().getTime();
      this.editMenu.portata.codFornitore = String(timeStamp);
    }
    if (this.editMenu.portata.idCategoriaMenu === 21) {
      this.beService
        .addSemilavorato(this.editMenu.portata, this.editMenu.idRisto)
        .then(res => {
          this.editMenu.portata = res;
          this.updMenu.emit(this.editMenu.idRisto);
        });
    } else if (this.editMenu.edit) {
      // MODIFICA PIETANZA
      this.beService
        .modifyPietanza(this.editMenu.portata)
        .then(res => {
          this.editMenu.portata = res;
          this.updMenu.emit(this.editMenu.idRisto);
        });
    } else {
      // SALVA PIETANZA
      this.beService
        .addPietanza(this.editMenu.portata)
        .then(res => {
          this.editMenu.portata = res;
          this.updMenu.emit(this.editMenu.idRisto);
        });
    }
  }
}
