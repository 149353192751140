import { TranslateService } from '@ngx-translate/core';
import { Risto, OrdineFirebase } from "./../interface/risto.interface";
import { FirebaseService } from "./../services/firebase.service";
import { map } from "rxjs/operators";
import { AngularFireAuth } from "angularfire2/auth";
import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material";
import { BeService } from "../services/be.service";
import { User } from "../interface/user.interface";

@Component({
  selector: "app-my-nav",
  templateUrl: "./my-nav.component.html",
  styleUrls: ["./my-nav.component.css"]
})
export class MyNavComponent implements OnInit {
  header: string;
  isFullscreen = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: { matches: any; }) => result.matches));
  user: User;
  ristorante: Risto;
  showSpinner: boolean;
  ordiniFirebase = {} as OrdineFirebase;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public afAuth: AngularFireAuth,
    public beService: BeService,
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
    public translate: TranslateService
  ) {
    const browserLang: string = translate.getBrowserLang();
    const lang = browserLang.match(/en|it|fr/) ? browserLang : "en";
    translate.setDefaultLang("it");
    translate.use(lang);
  }

  ngOnInit() {
    this.user = this.beService.getUser();
    if (!this.user) {
      this.beService
        .refreshUser(
          this.afAuth.auth.currentUser.email,
          this.afAuth.auth.currentUser.uid
        ).then(() => {
          this.user = this.beService.getUser();
          this.routingApp();
        });
    }
    else {
      this.routingApp();
    }
  }
  onActivate(e: any, scrollContainer: { scrollTop: number; }) {
    scrollContainer.scrollTop = 0;
  }

  routingApp() {
    switch (this.user.authority) {
      case "ROLE_ADMIN":
        this.router.navigate(['/home']);
        break;
      case "ROLE_BAR":
        this.router.navigate(['/cassa']);
        break;
      case "ROLE_CHEF":
        this.router.navigate(['/monitor']);
        break;
      case "ROLE_RISTO":
        this.router.navigate(['/risto']);
        break;
      default:
        this.router.navigate(['/home']);
        break;
    }
  }
  //  toggleFullscreen() {
  //    if (screenfull.enabled) {
  //      screenfull.toggle();
  //      this.isFullscreen = !this.isFullscreen;
  //    }
  //  }
}
