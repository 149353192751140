import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { User } from '../interface/user.interface';
import { Risto } from '../interface/risto.interface';
import {CategoriaMenu, EditMenu, Portate} from '../interface/menu.interface';
import {BeService} from '../services/be.service';

@Component({
  selector: 'app-list-menu',
  templateUrl: './list-menu.component.html',
  styleUrls: ['./list-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ListMenuComponent implements OnInit {
  catMenuDBRisto: CategoriaMenu[];
  currentLang: string;
  objMenu: EditMenu;
  editMenu: boolean;
  editUser: boolean;
  user: User;
  risto: Risto;
  barUser: boolean;
  constructor(
    public beService: BeService,
    private router: Router,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    });
    this.initUser();
    this.beService.currentRistoDetail.subscribe(res => {
      if (res.id) {
        this.risto = res;
        this.beService.getCatMenuRistoranteAll(+this.risto.id).then(ris => {
          this.catMenuDBRisto = ris;
          this.setCustomCat();
        });
      }
    });
  }

  private setCustomCat() {
    if (this.user.authority === 'ROLE_BAR') {
      // MENU BAR REMOVE SEMILAVORATI
      const findIndex = this.catMenuDBRisto.findIndex(item => item.id === 21);
      if (findIndex !== -1) {
        this.catMenuDBRisto.splice(findIndex, 1);
      }
    }
    if (this.user.authority === 'ROLE_CHEF') {
      // TAVOLA CALDA
      this.beService.getCatMenuById(8).then(catId => {
        this.catMenuDBRisto.push(catId);
      });
      // SEMILAVORATI
      this.beService.getCatMenuById(21).then(catId => {
        this.catMenuDBRisto.push(catId);
      });
    }
  }

  initUser() {
    this.user = this.beService.getUser();
    if (!this.user) {
      this.router.navigate(['/home']);
    } else {
      this.editUser = this.user.authority === 'ROLE_CHEF';
      this.barUser = this.user.authority === 'ROLE_BAR';
    }
  }
  addPortata(cat: CategoriaMenu) {
    this.editPortata(cat, null);
  }

  editPortata(cat: CategoriaMenu, portata: Portate) {
    this.objMenu = {} as EditMenu;
    this.objMenu.portata = {} as Portate;
    this.editMenu = true;
    if (portata) {
      this.objMenu.portata = portata;
      this.objMenu.edit = true;
    } else {
      this.objMenu.portata = {} as Portate;
      this.objMenu.edit = false;
      this.objMenu.portata.titolo = '';
      this.objMenu.portata.descrizione = '';
    }
    this.objMenu.portata.idCategoriaMenu = cat.id;
    this.objMenu.idRisto = +this.risto.id;
    this.objMenu.categorieMenu = this.catMenuDBRisto;
  }

  savePortata() {
    this.beService.getCatMenuRistoranteAll(+this.risto.id).then(ris => {
      this.catMenuDBRisto = ris;
      this.setCustomCat();
      this.editMenu = false;
    });
  }

  toogle(portata: Portate) {
    portata.abilitato = !portata.abilitato;
    this.beService.modifyFlagPietanza(portata);
  }
  backMenu() {
    this.editMenu = false;
  }

}
