import { OrdineDettaglio, OrdineBar } from './interface/risto.interface';
import { ScaricoMagazzino, ProdottoMagazzino, IngredienteMagazzino } from './interface/indaco.interface';
export class MagazzinoUtility {

  // tslint:disable-next-line:max-line-length
    static scaricoMagazzino(idMagazzino: number, idDocumento: number, fiscale: string, metodo: string, listOrdDett: OrdineDettaglio[], totaleDaPagare: number, sconto: number): ScaricoMagazzino {
        const scarico = {} as ScaricoMagazzino;
        totaleDaPagare = +totaleDaPagare - +sconto;
        scarico.idTipoDocumento = idDocumento;
        scarico.fiscale = fiscale;
        scarico.magazzino = idMagazzino;
        scarico.pagoBancomat = 0;
        scarico.pagoBonifico = 0;
        scarico.pagoContanti = metodo === 'contante' ? totaleDaPagare : 0;
        scarico.pagoCartaCredito = metodo === 'carta' ? totaleDaPagare : 0;
        scarico.pagoBuono = 0;
        scarico.totale = totaleDaPagare;
        scarico.totaleSconto = sconto;
        const prodotti = new Array<ProdottoMagazzino>();
        listOrdDett.forEach(ord => {

            const prod = {} as ProdottoMagazzino;
            prod.codice = ord.pietanza.codFornitore;
            prod.descrizione = ord.pietanza.titolo;
            prod.prezzoUnitario = ord.prezzo;
            prod.quantita = ord.quantita;
            prod.totale = ord.quantita * ord.prezzo;
            prod.prodottoSconto = false;
            prod.dataIn = ord.data_in;
            prod.dataOut = ord.data_out;
            prod.unitaMisuraCarico = ord.pietanza.unitaMisuraCarico;
            prod.unitaMisuraScarico = ord.pietanza.unitaMisuraScarico;
            prod.conversione = ord.pietanza.conversione;
            prod.ingredienti = new Array<IngredienteMagazzino>();
            if (ord.ordDetSups && ord.ordDetSups.length > 0) {
                ord.ordDetSups.forEach(sup => {
                    const indexSup = ord.pietanza.pietanzaSupplementos.findIndex(item => item.ingrediente.id === sup.ingrediente.id);
                    const ing = {} as IngredienteMagazzino;
                    ing.codice = ord.pietanza.pietanzaSupplementos[indexSup].ingrediente.codice;
                    ing.quantita = ord.pietanza.pietanzaSupplementos[indexSup].quantita;
                    prod.ingredienti.push(ing);
                });
            }
            if (ord.pietanza.pietanzaIngredientes && ord.pietanza.pietanzaIngredientes.length > 0) {

                ord.pietanza.pietanzaIngredientes.forEach(ordIng => {
                    let indexNonIng = -1;
                    if (ord.ordDetIngs && ord.ordDetIngs.length > 0) {
                        indexNonIng = ord.ordDetIngs.findIndex(item => item.ingrediente.id === ordIng.ingrediente.id);
                    }
                    if (indexNonIng === -1) {
                        const ing = {} as IngredienteMagazzino;
                        ing.codice = ordIng.ingrediente.codice;
                        ing.quantita = ordIng.quantita;
                        prod.ingredienti.push(ing);
                    }
                });            }
            prodotti.push(prod);
        });
        if (sconto > 0) {
            const prod = {} as ProdottoMagazzino;
            prod.codice = 'SCONTO';
            prod.descrizione = 'Sconto';
            prod.prezzoUnitario = sconto;
            prod.quantita = 1;
            prod.totale = sconto;
            prod.ingredienti = new Array<IngredienteMagazzino>();
            prod.prodottoSconto = true;
            prodotti.push(prod);
        }
        scarico.prodotti = prodotti;
        return scarico;
    }

  // tslint:disable-next-line:max-line-length
    static scaricoMagazzinoCassa(idMagazzino: number, idDocumento: number, fiscale: string, metodo: string, listOrdDett: OrdineBar[], totaleDaPagare: number, sconto: number): ScaricoMagazzino {
        const scarico = {} as ScaricoMagazzino;
        scarico.idTipoDocumento = idDocumento;
        scarico.fiscale = fiscale;
        scarico.magazzino = idMagazzino;
        scarico.pagoBancomat = 0;
        scarico.pagoBonifico = 0;
        scarico.pagoContanti = metodo === 'contante' ? totaleDaPagare : 0;
        scarico.pagoCartaCredito = metodo === 'carta' ? totaleDaPagare : 0;
        scarico.pagoBuono = 0;
        scarico.totale = totaleDaPagare;
        scarico.totaleSconto = sconto;
        const prodotti = new Array<ProdottoMagazzino>();
        listOrdDett.forEach(ord => {

            const prod = {} as ProdottoMagazzino;
            prod.codice = ord.pietanza.codFornitore;
            prod.descrizione = ord.pietanza.titolo;
            prod.prezzoUnitario = ord.prezzo;
            prod.quantita = ord.quantita;
            prod.totale = ord.quantita * ord.prezzo;
            prod.prodottoSconto = false;
            prod.ingredienti = new Array<IngredienteMagazzino>();
            if (ord.pietanza.pietanzaIngredientes && ord.pietanza.pietanzaIngredientes.length > 0) {
                ord.pietanza.pietanzaIngredientes.forEach(ordIng => {
                    const ing = {} as IngredienteMagazzino;
                    ing.codice = ordIng.ingrediente.codice;
                    ing.quantita = ordIng.quantita;
                    prod.ingredienti.push(ing);
                });
            }
            prodotti.push(prod);
        });
        if (sconto > 0) {
            const prod = {} as ProdottoMagazzino;
            prod.codice = 'SCONTO';
            prod.descrizione = 'Sconto';
            prod.prezzoUnitario = sconto;
            prod.quantita = 1;
            prod.totale = sconto;
            prod.ingredienti = new Array<IngredienteMagazzino>();
            prod.prodottoSconto = true;
            prodotti.push(prod);
        }
        scarico.prodotti = prodotti;
        return scarico;
    }
}
