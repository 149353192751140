
import { BeService } from 'src/app/services/be.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ScontoDialogModel, ScontoDialogComponent } from '../util/sconto-dialog/sconto-dialog.component';
import {CategoriaMenu, Portate} from '../interface/menu.interface';
import {OrdineBar, ProdottiScFiscale, Risto, ScontrinoFiscale} from '../interface/risto.interface';
import {LogoutUtility} from '../app-login/logout-utility';
import {MagazzinoUtility} from '../magazzino-utility';
import {MenuDialogComponent, MenuDialogModel} from '../util/menu-dialog/menu-dialog.component';

@Component({
  selector: 'app-cassa',
  templateUrl: './cassa.component.html',
  styleUrls: ['./cassa.component.css']
})
export class CassaComponent implements OnInit {
  C = 'contante';
  CC = 'carta';
  catMenuDBRisto: CategoriaMenu[];
  orderList: Array<OrdineBar>;
  currentLang: string;
  catSelMenu: number;
  prezzoTotale = 0;
  sconto = 0;
  totaleScontrino: number;
  risto: Risto;

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private dialog: MatDialog,
    public translate: TranslateService,
    public beService: BeService
  ) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    });
    this.beService.currentRistoDetail.subscribe(res => {
      if (res.id) {
        this.risto = res;
        this.initCategorie();
      }
    });

  }
  initCategorie() {
    this.beService.getCatMenuRistoranteEnable(+this.risto.id).then(ris => {
      this.catMenuDBRisto = ris;
      const findIndex = this.catMenuDBRisto.findIndex(item => item.id === 21);
      if (findIndex !== -1) {
        this.catMenuDBRisto.splice(findIndex, 1);
      }
    });
  }

  setCatSelMenu(id: number) {
    this.catSelMenu = id;
  }

  filterBy(catId: number) {
    return this.catMenuDBRisto.filter(categoria => catId === categoria.id);
  }
  getNameCat(idCat: number): string {
    const cat = this.filterBy(idCat);
    return cat[0].categoria;
  }


  logout() {
    const u = new LogoutUtility(this.router, this.afAuth, this.dialog);
    u.logout();
  }

  addScontrinoFis(portata: Portate) {
    if (!this.orderList) {
      this.orderList = new Array<OrdineBar>();
    }
    const newEntry = {} as OrdineBar;
    newEntry.idPortata = portata.id + '';
    newEntry.quantita = 1;
    newEntry.prezzo = portata.prezzo;
    newEntry.pietanza = portata;
    this.orderList.push(newEntry);
    this.ricalcolaTotale();
    this.sconto = 0;
    this.prezzoTotale = this.totaleScontrino;
  }
  deleteEntry(index: number) {
    this.orderList.splice(index, 1);
    this.ricalcolaTotale();
    this.sconto = 0;
    this.prezzoTotale = this.totaleScontrino;
  }
  incQ(index: number) {
    let curQuant = +this.orderList[index].quantita;
    const curPrezzo = +this.orderList[index].prezzo;
    curQuant += 1;
    this.orderList[index].quantita = curQuant;
    this.prezzoTotale += curPrezzo;
    this.ricalcolaTotale();
    this.sconto = 0;
    this.prezzoTotale = this.totaleScontrino;
  }
  decQ(index: number) {
    if (this.orderList[index].quantita > 1) {
      let curQuant = +this.orderList[index].quantita;
      const curPrezzo = +this.orderList[index].prezzo;
      curQuant -= 1;
      this.orderList[index].quantita = curQuant;
      this.prezzoTotale -= curPrezzo;
      this.ricalcolaTotale();
      this.sconto = 0;
      this.prezzoTotale = this.totaleScontrino;
    }

  }

  addSconto() {
    this.prezzoTotale += this.sconto;
    this.sconto = 0;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = new ScontoDialogModel(
      'Importo Scontato', this.prezzoTotale
    );

    const dialogRef = this.dialog.open(ScontoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.sconto = this.prezzoTotale - dialogResult;
        this.prezzoTotale -= this.sconto;
      }
    });
  }

  ricalcolaTotale() {
    this.totaleScontrino = 0;
    this.orderList.forEach(ord => {
      const prezzoPort = ord.quantita * ord.prezzo;
      this.totaleScontrino += prezzoPort;
    });
  }

  ordinePAG(metodo: string) {
    const tipoPag = metodo === 'CC' ? this.CC : this.C;
    let scontrinoF: ScontrinoFiscale;
    scontrinoF = this.createScontrinoFiscale(tipoPag, this.orderList, this.sconto);
    this.stampaFis(scontrinoF);
  }
  stampaFis(scontrinoF: ScontrinoFiscale) {
    this.beService.stampaFiscaleBar(scontrinoF).then(() => {
      this.scaricaMagazzino(scontrinoF.metodopagamento);
    });
  }

  scaricaMagazzino(metodo: string) {
    const fiscale = metodo === 'A' ? 'N' : 'Y';
    // tslint:disable-next-line:max-line-length
    const scaricoMagazzino = MagazzinoUtility.scaricoMagazzinoCassa(+this.risto.id, 26, fiscale, metodo, this.orderList, this.prezzoTotale, this.sconto);
    this.beService.scaricoMagazzinoBar(scaricoMagazzino).then(() => {
      this.resetOrdine();
    });

  }

  private createScontrinoFiscale(metodo: string, listOrdDett: OrdineBar[], sconto: number): ScontrinoFiscale {
    const scontrino = {} as ScontrinoFiscale;
    scontrino.metodopagamento = metodo;
    scontrino.scontosultotale = sconto;
    const prodotti = new Array<ProdottiScFiscale>();
    listOrdDett.forEach(ord => {
      const prodSc = {} as ProdottiScFiscale;
      prodSc.quantita = ord.quantita;
      prodSc.descrizione = ord.pietanza.titolo;
      prodSc.iva = ord.pietanza.iva;
      prodSc.prezzo = ord.prezzo;
      prodotti.push(prodSc);
    });
    scontrino.prodotti = prodotti;
    return scontrino;
  }

  resetOrdine() {
    this.orderList = new Array<OrdineBar>();
    this.prezzoTotale = 0;
    this.sconto = 0;
    this.totaleScontrino = 0;
  }

  scaricaNoVendita() {
    this.prezzoTotale = 0;
    this.sconto = 0;
    this.scaricaMagazzino('A');
  }

  scaricaSF(tipoScarico: string) {
    // tslint:disable-next-line:max-line-length
    const scaricoMagazzino = MagazzinoUtility.scaricoMagazzinoCassa(+this.risto.id, 26, tipoScarico, tipoScarico, this.orderList, this.prezzoTotale, this.sconto);
    this.beService.scaricoMagazzinoBar(scaricoMagazzino).then(() => {
      this.resetOrdine();
    });
  }

  addOrderList(idRistorante: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = new MenuDialogModel(
      'Aggiungi Prodotto',
      'Cerca prodotto', idRistorante, 0
    );

    const dialogRef = this.dialog.open(MenuDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.addScontrinoFis(dialogResult);
      }
    });

  }
  refresh() {
    location.reload();
  }

}

