import { ScaricoMagazzinoComponent } from './scarico-magazzino/scarico-magazzino.component';
import { ScaricoMagazzino } from './interface/indaco.interface';
import { ListMenuComponent } from './list-menu/list-menu.component';
import { TavoloComponent } from './tavolo/tavolo.component';
import { RistoComponent } from './risto/risto.component';
import { MonitorComponent } from './monitor/monitor.component';
import { CassaComponent } from './cassa/cassa.component';
import { HomeComponent } from './home/home.component';
import { MyNavComponent } from './my-nav/my-nav.component';
import { Routes } from '@angular/router';
import { LoginComponent } from './app-login/login/login.component';
import { ResetPasswordComponent } from './app-login/reset-password/reset-password.component';
import { AuthGuard } from './services/auth.service';
import {SemilavoratiComponent} from './semilavorati/semilavorati.component';
import {StoricoComponent} from './storico/storico.component';

export const AppRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'reset', component: ResetPasswordComponent },
  {
    path: '',
    component: MyNavComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'cassa',
        component: CassaComponent
      },
      {
        path: 'monitor',
        component: MonitorComponent
      },
      {
        path: 'risto',
        component: RistoComponent
      },
      {
        path: 'tavolo/:idTavolo',
        component: TavoloComponent
      },
      {
        path: 'listmenu',
        component: ListMenuComponent
      },
      {
        path: 'magazzino',
        component: ScaricoMagazzinoComponent
      },
      {
        path: 'semilavorato',
        component: SemilavoratiComponent
      },
      {
        path: 'storico',
        component: StoricoComponent
      }
    ]
  }
];
