import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { MatDialog } from '@angular/material';
import { MatDialogConfig } from '@angular/material';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../util/confirm-dialog/confirm-dialog.component';
export class LogoutUtility {
    constructor(private router: Router, private afAuth: AngularFireAuth, private dialog: MatDialog) { }

    logout() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = new ConfirmDialogModel(
            'Conferma Disconnessione',
            'Confermi di voler uscire',
            '', false
        );

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((dialogResult: any) => {
            if (dialogResult) {
                this.afAuth.auth.signOut();
                this.router.navigate(['']).then(() => location.reload(true));

            }
        });
    }

}
