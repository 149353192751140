import { environment } from '../../environments/environment';
import {
  Ordine,
  OrdineFirebase,
  OrdineUpd,
  Tavolo,
  PietanzaProntaReq,
  OrdineStampa,
  OrdineDettaglioStampa,
  OrdineReq,
  ScontrinoFiscale, OrdineStoricoReq, OrdineStorico
} from '../interface/risto.interface';
import {
  Allergene,
  Ingredienti,
  CategoriaMenu,
  Portate
} from '../interface/menu.interface';
import { GalleryModel } from '../interface/gallery.interface';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../interface/user.interface';
import { Risto } from '../interface/risto.interface';
import {ScaricoMagazzino, UnitaMisura} from '../interface/indaco.interface';

const url = environment.urlBe;

@Injectable()
export class BeService {
  auth_key: string;
  user: User;
  // NAV e USER DETAIL SHARE OBJECT
  private defaultOrdiniFirebase = new BehaviorSubject({} as OrdineFirebase);
  currentOrdiniFirebase = this.defaultOrdiniFirebase.asObservable();
  private defaultRistoDetail = new BehaviorSubject({} as Risto);
  currentRistoDetail = this.defaultRistoDetail.asObservable();

  constructor(private http: HttpClient) { }

  // CURRENT USER
  public getUserRest(username: string, uid: string): Promise<any> {
    return this.http
      .post(
        environment.urlBe + '/public/login',
        { username: username, password: uid },
        { observe: 'response' }
      )
      .toPromise();

  }

  public getUserByUsername(username: string) {
    return this.http
      .post<User>(environment.urlBe + '/public/getUserByUsername', { username: username }, {
        headers: this.setHeaderAuth(this.auth_key)
      })
      .toPromise();
  }

  public async refreshUser(email: string, uid: string) {
    const res = await this.getUserRest(email, uid);
    this.auth_key = res.headers.get('auth');
    this.setUser(res.body);
  }

  public setUser(user: any) {
    this.user = {} as User;
    this.user.username = user.username;
    if (user.authorities != null && user.authorities[0] != null) {
      this.user.authority = user.authorities[0].authority;
    }
    this.getUserByUsername(user.username).then(res => {
      if (res.ristorantes && res.ristorantes.length > 0) {
        this.user.ristorantes = res.ristorantes;
        if (res.ristorantes.length === 1) {
          this.changeRistoDetail(res.ristorantes[0]);
        }
      }
    });
  }

  public getUser(): User {
    return this.user;
  }

  changeOrdiniFirebase(ordiniFirebase: OrdineFirebase) {
    this.defaultOrdiniFirebase.next(ordiniFirebase);
  }

  changeRistoDetail(ristoDetail: Risto) {
    this.defaultRistoDetail.next(ristoDetail);
  }

  private handleError(error: Response | any) {
    return Observable.throw(error.message || error);
  }

  private setHeaderAuth(auth_key: string) {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      auth: auth_key
    });
  }

  // TAVOLO
  getListTavoli(idRisto: number) {
    return this.http
      .get<Array<Tavolo>>(environment.urlBe + '/public/getAllTable/' + idRisto)
      .toPromise();
  }

  getTavolo(idTavolo: number) {
    return this.http
      .get<Tavolo>(environment.urlBe + '/public/getTableByID/' + idTavolo)
      .toPromise();
  }

  getTavoloByNome(idRisto: number, nomeTavolo: string) {
    return this.http
      .get<Tavolo>(environment.urlBe + '/public/getTableByNome/' + idRisto + '/' + nomeTavolo)
      .toPromise();
  }


  addTavolo(tavolo: Tavolo) {
    return this.http
      .post<Tavolo>(environment.urlBe + '/public/addTavolo', tavolo, {
        headers: this.setHeaderAuth(this.auth_key)
      })
      .toPromise();
  }

  deleteTavolo(idTavolo: string) {
    this.http
      .delete(environment.urlBe + '/public/deleteTable/' + idTavolo, {
        headers: this.setHeaderAuth(this.auth_key)
      });
  }

  modifyTable(tavolo: Tavolo) {
    return this.http
      .put<Tavolo>(environment.urlBe + '/public/modifyTable', tavolo)
      .toPromise();
  }

  // ORDINI
  getOrdiniMonitorByTavolo(idRisto: number, idTavolo: number) {
    return this.http
      .get<any>(environment.urlBe + '/public/getOrdiniByIdRistoForTavolo/' + idRisto + '/' + idTavolo)
      .toPromise();
  }

  getOrdiniByTavolo(idRisto: number, idTavolo: number) {
    return this.http
      .get<any>(environment.urlBe + '/public/getOrdiniDettByIdRistoForTavolo/' + idRisto + '/' + idTavolo)
      .toPromise();
  }
  getOrdiniLightByTavolo(idRisto: number, idTavolo: number) {
    return this.http
      .get<any>(environment.urlBe + '/public/getOrdiniLightByIdRistoForTavolo/' + idRisto + '/' + idTavolo)
      .toPromise();
  }
  stampaPreconto(ordine: any, nomeTavolo: string, coperti: number, prezzoCop: number) {
    const ordineStampa = {} as OrdineStampa;
    ordineStampa.idTavolo = ordine.id;
    ordineStampa.nomeTavolo = nomeTavolo;
    ordineStampa.utente = ordine.utente;
    const listDettagli = new Array<OrdineDettaglioStampa>();
    if (prezzoCop > 0) {
      const ordS = {} as OrdineDettaglioStampa;
      ordS.nomePietanza = 'COPERTO';
      ordS.quantita = coperti;
      ordS.prezzo = prezzoCop;
      listDettagli.push(ordS);
    }
    ordine.ordineDettaglios.forEach(ord => {
      if (!ord.pagato && ord.prezzo > 0) {
        const ordS = {} as OrdineDettaglioStampa;
        ordS.nomePietanza = ord.pietanza.titolo;
        if (ord.ordDetIngs) {
          const ingS = new Array<string>();
          ord.ordDetIngs.forEach((ing: { ingrediente: { ingrediente: string; }; }) => ingS.push(ing.ingrediente.ingrediente));
          ordS.stampaOrdDetIng = ingS;
        }
        if (ord.ordDetSups) {

          const supS = new Array<string>();
          ord.ordDetSups.forEach((sup: { ingrediente: { ingrediente: string; }; }) => supS.push(sup.ingrediente.ingrediente));
          ordS.stampaOrdDetSup = supS;
        }
        ordS.prezzo = ord.prezzo * ord.quantita;
        ordS.quantita = ord.quantita;
        listDettagli.push(ordS);
      }
    });
    ordineStampa.ordineDettaglios = listDettagli;
    ordineStampa.totale = ordine.totale + prezzoCop;
    ordineStampa.totalePersona = ordineStampa.totale > 0 && coperti > 1 ? ordineStampa.totale / coperti : 0;
    this.getBase64(ordineStampa).then(res => {
      this.http
        .post(
          environment.urlRisto + '/stampahash', { printerName: environment.stampaRisto, hash: res.hash }
        ).subscribe();
    });

  }
  private getBase64(ordineStampa: OrdineStampa) {
    return this.http
      .post<any>(
        environment.urlBe + '/public/stampaPreconto', ordineStampa
      ).toPromise();
  }
  stampaCaricoMagazzino(ordine: any) {
    const ordineStampa = {} as OrdineStampa;
    const listDettagli = new Array<OrdineDettaglioStampa>();
    ordine.ordineDettaglios.forEach(ord => {
      const ordS = {} as OrdineDettaglioStampa;
      ordS.nomePietanza = ord.pietanza.titolo;
      ordS.quantita = ord.quantita;
      listDettagli.push(ordS);
    });
    ordineStampa.ordineDettaglios = listDettagli;
    this.getBase64Carico(ordineStampa).then(res => {
      this.http
        .post(
          environment.urlRisto + '/stampahash', { printerName: environment.stampaCucina, hash: res.hash }
        ).subscribe();
    });
  }
  private getBase64Carico(ordineStampa: OrdineStampa) {
    return this.http
      .post<any>(
        environment.urlBe + '/public/stampaCaricoMagazzino', ordineStampa
      ).toPromise();
  }

  addDetailOrdineRisto(ordine: OrdineReq) {
    return this.http
      .post<any>(
        environment.urlBe + '/public/addDetailOrdineRisto', ordine,
        {
          headers: this.setHeaderAuth(this.auth_key)
        }
      ).toPromise();
  }

  deleteOrderDetail(ordine: OrdineReq) {
    return this.http
      .post<any>(
        environment.urlBe + '/public/deleteDetailOrdine', ordine,
        {
          headers: this.setHeaderAuth(this.auth_key)
        }
      ).toPromise();
  }
  stampaFiscaleRisto(scontrinoFiscale: ScontrinoFiscale) {
    return this.http
      .post<any>(
        environment.urlRisto + '/scontrinofiscale', scontrinoFiscale
      ).toPromise();
  }
  stampaFiscaleBar(scontrinoFiscale: ScontrinoFiscale) {
    return this.http
      .post<any>(
        environment.urlBar + '/scontrinofiscale', scontrinoFiscale
      ).toPromise();
  }

  // GET USER BY ROLE
  getListUserByRole(role: string) {
    return this.http
      .get<Array<User>>(environment.urlBe + '/public/getUserByRole/' + role, {
        headers: this.setHeaderAuth(this.auth_key)
      })
      .toPromise();
  }

  setPietanzaPronta(pietanzaProntaReq: PietanzaProntaReq) {
    return this.http
      .post<any>(
        environment.urlBe + '/public/setPietanzaPronta', pietanzaProntaReq,
        {
          headers: this.setHeaderAuth(this.auth_key)
        }
      ).toPromise();
  }


  // RISTO REST

  // CATEGORIE MENU
  getListCategorieMenu() {
    return this.http
      .get<Array<CategoriaMenu>>(environment.urlBe + '/public/getListCategoriaMenu')
      .toPromise();
  }

  addListCategorieMenu(catMenu: CategoriaMenu) {
    return this.http
      .post<CategoriaMenu>(environment.urlBe + '/public/addCategoriaMenu', catMenu, {
        headers: this.setHeaderAuth(this.auth_key)
      })
      .toPromise();
  }
  modifyListCategorieMenu(catMenuTrad: CategoriaMenu) {
    return this.http
      .put<CategoriaMenu>(
        environment.urlBe + '/public/modifyCategoriaMenu/' + catMenuTrad.id,
        catMenuTrad,
        {
          headers: this.setHeaderAuth(this.auth_key)
        }
      )
      .toPromise();
  }

  // CATEGORIE MENU RISTORANTE
  getCatMenuRistorante() {
    return this.http
      .get<Array<any>>(
        environment.urlBe + '/public/getCategoriaMenuRistorante'
      )
      .toPromise();
  }

  getCatMenuRistoranteEnable(idLocale: number) {
    return this.http
      .get<Array<any>>(
        environment.urlBe + '/public/getCategoriaMenuEnable/' + idLocale
      )
      .toPromise();
  }

  getCatMenuRistoranteAll(idLocale: number) {
    return this.http
      .get<Array<any>>(
        environment.urlBe + '/public/getCategoriaMenuAll/' + idLocale
      )
      .toPromise();
  }

  getCatMenuById(idCategoria: number) {
    return this.http
      .get<CategoriaMenu>(
        environment.urlBe + '/public/getCategoriaMenuById/' + idCategoria
      )
      .toPromise();
  }

  getProgressivoComanda(idRisto: number, idTavolo: number) {
    return this.http
      .get<any>(environment.urlBe + '/public/getProgressivoComanda/' + idRisto + '/' + idTavolo)
      .toPromise();
  }

  addCatMenuRistorante(catMenu: CategoriaMenu) {
    return this.http
      .post<CategoriaMenu>(
        environment.urlBe + '/public/addCategoriaMenuRistorante',
        catMenu,
        {
          headers: this.setHeaderAuth(this.auth_key)
        }
      )
      .toPromise();
  }
  modifyCatMenuRistorante(catMenu: CategoriaMenu) {
    return this.http
      .put<CategoriaMenu>(
        environment.urlBe + '/public/modifyCategoriaMenuRistorante/' + catMenu.id,
        catMenu,
        {
          headers: this.setHeaderAuth(this.auth_key)
        }
      )
      .toPromise();
  }

  getRistoById(idRisto: number) {
    return this.http
      .get<Risto>(environment.urlBe + '/public/getRistoranteByID/' + idRisto)
      .toPromise();
  }
  addRisto(risto: Risto) {
    return this.http
      .post<Risto>(environment.urlBe + '/public/addRistorante', risto, {
        headers: this.setHeaderAuth(this.auth_key)
      })
      .toPromise();
  }
  editRisto(idRisto: number, risto: Risto) {
    return this.http
      .put<Risto>(environment.urlBe + '/public/modifyRistorante/' + idRisto, risto, {
        headers: this.setHeaderAuth(this.auth_key)
      })
      .toPromise();
  }
  getEnabledRistorante() {
    return this.http
      .get<Array<Risto>>(environment.urlBe + '/public/getEnabledRistorante')
      .toPromise();
  }

  public uploadPhoto(gallery: GalleryModel) {
    const promise = new Array<any>();
    let index = 0;
    const head = new HttpHeaders({
      auth: this.auth_key
    });
    gallery.imageGallery.forEach(b => {
      if (b.blob) {
        promise.push(
          this.http
            .post<any>(
              environment.urlBe + '/public/uploadFile',
              this.setFormdata(b.blob, index + 1, gallery),
              { headers: head }
            )
            .toPromise()
            .then(res => {
              b.path = res.fileDownloadUri;
            })
        );
      }
      index++;
    });
    return promise;
  }

  private setFormdata(b: Blob, index: number, gallery: GalleryModel) {
    const formdata = new FormData();
    const filename = String(Date.now());
    const extension = b.type.substr(b.type.indexOf('/') + 1);
    const nameExt = filename + '.' + extension;
    formdata.append('file', b, nameExt);
    formdata.append('path', gallery.nameGallery);
    formdata.append('imgId', filename);
    return formdata;
  }

  // ALLERGENI
  getListAllergeniTrad() {
    return this.http
      .get<Array<Allergene>>(environment.urlBe + '/public/getAllergeniList')
      .toPromise();
  }
  getListIngredienti(idRisto: number) {
    return this.http
      .get<Array<Ingredienti>>(environment.urlBe + '/public/getIngredientiList/' + idRisto)
      .toPromise();
  }
  addIngredienti(ingrediente: Ingredienti) {
    return this.http
      .post<Ingredienti>(environment.urlBe + '/public/addIngrediente', ingrediente, {
        headers: this.setHeaderAuth(this.auth_key)
      })
      .toPromise();
  }
  modifyIngrediente(ingrediente: Ingredienti) {
    return this.http
      .put<Ingredienti>(
        environment.urlBe + '/public/modifyIngrediente/' + ingrediente.id,
        ingrediente,
        {
          headers: this.setHeaderAuth(this.auth_key)
        }
      )
      .toPromise();
  }

  // PIETANZA
  public addPhotoPietanza(blob: Blob, idRisto: string) {
    const formdata: FormData = new FormData();
    const extension = blob.type.substr(blob.type.indexOf('/') + 1);
    formdata.append('file', blob, 'portata.' + extension);
    formdata.append('path', 'appsala/risto/' + idRisto + '/menu');
    formdata.append('imgId', String(Date.now()));
    const head = new HttpHeaders({
      auth: this.auth_key
    });
    return this.http
      .post<any>(environment.urlBe + '/public/uploadFile', formdata, { headers: head })
      .toPromise();
  }
  addPietanza(portata: Portate) {
    return this.http
      .post<Portate>(environment.urlBe + '/public/addPietanza', portata, {
        headers: this.setHeaderAuth(this.auth_key)
      })
      .toPromise();
  }

  modifyPietanza(portata: Portate) {
    return this.http
      .put<Portate>(environment.urlBe + '/public/modifyPietanza/' + portata.id, portata, {
        headers: this.setHeaderAuth(this.auth_key)
      })
      .toPromise();
  }

  addSemilavorato(portata: Portate, idRisto: number) {
    return this.http
      .post<Portate>(environment.urlBe + '/public/addSemilavorato/' + idRisto, portata, {
        headers: this.setHeaderAuth(this.auth_key)
      })
      .toPromise();
  }

  // ORDINI
  getListOrdini(idRistorante: number, idStatoOrdine: number) {
    return this.http
      .get<Array<Ordine>>(environment.urlBe + '/public/getCurrentOrderByIdRistoAndState/' + idRistorante + '/' + idStatoOrdine)
      .toPromise();
  }

  getStoricoOrdini(idRistorante: number, idStatoOrdine: number, numDay: number) {
    return this.http
      .get<Array<Ordine>>(environment.urlBe + '/public/getOldOrderByIdRistoAndState/' + idRistorante + '/' + idStatoOrdine + '/' + numDay)
      .toPromise();
  }

  updateOrder(ordine: OrdineUpd) {
    return this.http
      .put<Ordine>(
        environment.urlBe + '/public/updOrdine', ordine,
        {
          headers: this.setHeaderAuth(this.auth_key)
        }
      )
      .toPromise();
  }

  modifyFlagPietanza(portata: Portate) {
    return this.http
      .put<Portate>(
        environment.urlBe + '/public/modifyFlagPietanza/' + portata.id,
        portata,
        {
          headers: this.setHeaderAuth(this.auth_key)
        }
      )
      .toPromise();
  }

  closeOrdine(ordineReq: OrdineReq, nomeTavolo: string) {
    const promise = new Array<any>();
    const uscitaList = new Array<number>();
    ordineReq.ordineDettaglios.forEach(ord => {
      if (!ord.pronta) {
        const index = uscitaList.indexOf(ord.uscita);
        if (index === -1) {
          uscitaList.push(ord.uscita);
        }
      }
    });
    uscitaList.forEach(u => {
      const piet = {} as PietanzaProntaReq;
      piet.idOrdine = ordineReq.id;
      piet.nomeTavolo = nomeTavolo;
      piet.uscita = u;
      promise.push(this.http
        .post<any>(environment.urlBe + '/public/setPietanzaPronta', piet).toPromise());
    });
    promise.push(this.http.put<Portate>(environment.urlBe + '/public/closeOrdine', ordineReq, {
        headers: this.setHeaderAuth(this.auth_key)
    }).toPromise());
    return Promise.all(promise);
  }

  storico(storicoReq: OrdineStoricoReq) {
    return this.http
      .post<OrdineStorico[]>(environment.urlBe + '/public/ordiniStoricoByIdRisto', storicoReq)
      .toPromise();
  }
  // INDACO REST
  scaricoMagazzinoRisto(scarico: ScaricoMagazzino) {
    return this.http
      .post(environment.indaco + '/venditaApproposit', scarico)
      .toPromise();
  }

  caricoMagazzinoBar(scarico: ScaricoMagazzino) {
    return this.http
      .post(environment.indaco + '/caricoBarApproposit', scarico)
      .toPromise();
  }

  ingredientiSemiLavorato(scarico: ScaricoMagazzino) {
    return this.http
      .post(environment.indaco + '/ingredientiSemiLavorato', scarico)
      .toPromise();
  }

  scaricoMagazzinoBar(scarico: ScaricoMagazzino) {
    return this.http
      .post(environment.indaco + '/venditaBarApproposit', scarico)
      .toPromise();
  }
  unitaMisura() {
    return this.http
      .get<Array<UnitaMisura>>(environment.indaco + '/getUnitaMisura')
      .toPromise();
  }

}
