import {ActivatedRoute, Router} from '@angular/router';
import {MagazzinoUtility} from './../magazzino-utility';
import {FirebaseService} from './../services/firebase.service';
import {MenuDialogComponent, MenuDialogModel} from './../util/menu-dialog/menu-dialog.component';
import {Ordine, OrdineDettaglio, OrdineReq, ProdottiScFiscale, Risto, ScontrinoFiscale, Tavolo} from './../interface/risto.interface';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {BeService} from 'src/app/services/be.service';
import {ScontoDialogComponent, ScontoDialogModel} from '../util/sconto-dialog/sconto-dialog.component';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../util/confirm-dialog/confirm-dialog.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-tavolo',
  templateUrl: './tavolo.component.html',
  styleUrls: ['./tavolo.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TavoloComponent implements OnInit {
  C = 'contante';
  CC = 'carta';
  tavolo: Tavolo;
  risto: Risto;
  sub: any;
  currentLang: string;
  ordini: Ordine;
  ordiniLight: Ordine;
  pagatoParziale: number;
  disableSconto = false;
  pagaAll = false;
  pagaSel = false;
  pagaChiudiScontrino = false;
  dividi = false;
  dividiCoperti = 1;
  totaleDaPagare: number;
  listaPieSel = new Array<OrdineDettaglio>();
  copSel = 0;
  totPieSel = 0;
  listPagaAll: OrdineDettaglio[];
  totPagaAll = 0;
  prezzoSelezionati = 0;
  totaleParziale = 0;

  constructor(private router: Router, private route: ActivatedRoute,
              public beService: BeService,
              public translate: TranslateService,
              public dialog: MatDialog,
              public firebaseService: FirebaseService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.beService.getTavolo(params['idTavolo']).then(tav => {
        this.tavolo = tav;
        this.beService.currentRistoDetail.subscribe(res => {
          if (res.id) {
            this.risto = res;
            this.firebaseService.monitorOrdineTavolo(this.tavolo.nome).subscribe(() => this.getOrdini());
          }
        });
      });
    });
    this.translate.onLangChange.subscribe(() => this.currentLang = this.translate.currentLang);
  }

  private getOrdini() {
    this.beService.getOrdiniByTavolo(+this.risto.id, this.tavolo.id).then(ordini => {
      console.log('ORDINI', ordini);
      if (ordini.length > 0) {
        console.log('ORDINI LENGTH', ordini.length);
        this.ordini = ordini[0];
        let totPagato = 0;
        this.ordini.ordineDettaglios.forEach(dett => {
          if (dett.pagato) {
            totPagato += +dett.prezzo;
          }
        });
        this.totaleParziale = this.ordini.totale;
        this.totaleDaPagare = this.ordini.totale + (this.tavolo.coperti * this.risto.prezzoCoperto);
        this.beService.getOrdiniLightByTavolo(+this.risto.id, this.tavolo.id).then(ordiniLight => {
          this.ordiniLight = ordiniLight[0];
          this.ordiniLight.ordineDettaglios.sort((a, b) => a.pietanza.titolo > b.pietanza.titolo ? 1 : -1 );
        });
      }
    });
  }

  addOrderList(idRistorante: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = new MenuDialogModel(
      'Aggiungi Prodotto',
      'Cerca prodotto', idRistorante, 0
    );

    const dialogRef = this.dialog.open(MenuDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.createOrdineReq(dialogResult).then(ord =>
          this.beService.addDetailOrdineRisto(ord).then(res => this.getOrdini()));
      }
    });

  }

  private async createOrdineReq(dialogResult: any): Promise<OrdineReq> {
    return await this.beService.getProgressivoComanda(+this.risto.id, this.tavolo.id).then(res => {
      const ordineReq = {} as OrdineReq;
      ordineReq.ordineDettaglios = new Array();
      ordineReq.id = this.ordini.id;
      let totaleInc = this.ordini.totale;
      for (let i = 0; i < dialogResult.quantita; i++) {
        const ordineDettaglio = {} as OrdineDettaglio;
        ordineDettaglio.idPietanza = dialogResult.id;
        ordineDettaglio.prezzo = +dialogResult.prezzo;
        totaleInc += +dialogResult.prezzo;
        ordineDettaglio.quantita = 1;
        ordineDettaglio.uscita = +res.progressivo + 1;
        ordineReq.ordineDettaglios.push(ordineDettaglio);
      }
      ordineReq.totale = totaleInc;
      return ordineReq;
    });
  }

  private createCloseOrdineReq(listOrdDett: OrdineDettaglio[], coperti: number, totale: number, sconto: number): OrdineReq {
    const ordineReq = {} as OrdineReq;
    ordineReq.ordineDettaglios = listOrdDett;
    ordineReq.id = this.ordini.id;
    ordineReq.copertoPagato = coperti;
    ordineReq.totale = totale;
    ordineReq.sconto = sconto;
    return ordineReq;
  }

  removeDetail(ord: OrdineDettaglio) {
    const ordineReq = {} as OrdineReq;
    ordineReq.ordineDettaglios = new Array();
    ordineReq.id = this.ordini.id;
    if (ord.ordDetIngs) {
      ord.inOrdDetIngs = new Array<number>();
      ord.ordDetIngs.forEach(detIng => {
        ord.inOrdDetIngs.push(detIng.id);
      });
    }
    ordineReq.ordineDettaglios.push(ord);
    this.beService.deleteOrderDetail(ordineReq).then(() => {
      this.resetContatori();
      this.getOrdini();
  });
  }

  coperti(op: string) {
    this.tavolo.coperti = op === 'D' ? this.tavolo.coperti - 1 : this.tavolo.coperti + 1;
    this.totaleDaPagare = this.ordini.totale + (this.tavolo.coperti * this.risto.prezzoCoperto);
    this.beService.modifyTable(this.tavolo);
  }

  addSconto() {
    this.totaleDaPagare = this.totaleDaPagare + this.ordini.sconto;
    this.ordini.sconto = 0;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = new ScontoDialogModel(
      'Importo Scontato', this.totaleDaPagare
    );

    const dialogRef = this.dialog.open(ScontoDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.ordini.sconto = this.totaleDaPagare - dialogResult;
        this.totaleDaPagare -= this.ordini.sconto;
      }
    });
  }

  stampaPreConto() {
    const prezzoCop = this.tavolo.coperti * this.risto.prezzoCoperto;
    this.beService.stampaPreconto(this.ordiniLight, this.tavolo.nome, this.tavolo.coperti, prezzoCop);
  }

  pagaTutto() {
    this.pagaAll = true;
    this.pagaSel = false;
    this.disableSconto = false;
    this.listPagaAll = new Array<OrdineDettaglio>();
    this.totPagaAll = 0;
  }

  pagaSelezioni() {
    this.pagaAll = false;
    this.pagaSel = true;
    this.disableSconto = true;
    this.listaPieSel = new Array<OrdineDettaglio>();
    this.totaleDaPagare = this.totaleDaPagare + this.ordini.sconto;
    this.ordini.sconto = 0;
    this.copSel = 1;
    this.totPieSel = 0;
  }

  ordinePAG(metodo: string) {
    const tipoPag = metodo === 'CC' ? this.CC : this.C;
    const portateNonPagate = this.getSelNoPag();
    const portateSelezionate = this.listaPieSel ? this.listaPieSel.length : 0;
    if (this.pagaAll || (portateNonPagate === portateSelezionate && this.copSel === this.tavolo.coperti)) {
      if (!this.pagaAll) {
        this.pagaAll = true;
        this.listPagaAll = new Array<OrdineDettaglio>();
      }
      this.ordini.ordineDettaglios.forEach(ord => {
        if (!ord.pagato) {
          this.listPagaAll.push(ord);
          this.totPagaAll += +ord.prezzo;
        }
      });
      this.manageScaricaOrdine(metodo, tipoPag, false);
    } else if ((portateNonPagate === portateSelezionate && this.copSel !== this.tavolo.coperti)
      || (portateNonPagate !== portateSelezionate && this.copSel === this.tavolo.coperti)) {
      this.showErr('Numero di coperti selezionati non compatibile con la selezione delle portate !!!');
      this.listaPieSel = new Array<OrdineDettaglio>();
      this.totPieSel = 0;
      this.prezzoSelezionati = 0;
      this.pagaSel = false;
    } else {
      this.manageScaricaOrdine(metodo, tipoPag, true);
      this.pagaSel = false;
      this.pagaChiudiScontrino = false;
    }
  }

  private manageScaricaOrdine(metodo: string, tipoPag: string, selezioni: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = new ConfirmDialogModel(
      'Conferma operazione',
      'Operazione richiesta : ' + this.getOpFromMethod(metodo),
      '', false
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (metodo === 'CS') {
          this.scaricaOrdine(this.C, 'N', false);
        } else if (metodo === 'S') {
          this.scaricaOrdine('X', 'S', true);
        } else if (metodo === 'F') {
          this.scaricaOrdine('X', 'F', true);
        } else {
          // tslint:disable-next-line:max-line-length
          this.stampaFis(this.createScontrinoFiscale(tipoPag, selezioni ? this.listaPieSel : this.listPagaAll, this.ordini.sconto, selezioni ? this.copSel : this.tavolo.coperti, this.risto.prezzoCoperto));
        }
      } else {
        this.resetContatori();
      }
    });
  }

  private showErr(err: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = new ConfirmDialogModel(
      'Errore',
      err,
      '', true
    );
    this.dialog.open(ConfirmDialogComponent, dialogConfig);
  }

  private getSelNoPag(): number {
    let noPag = 0;
    this.ordini.ordineDettaglios.forEach(ord => {
      if (!ord.pagato) {
        noPag++;
      }
    });
    return noPag;
  }

  stampaFis(scontrinoF: ScontrinoFiscale) {
    this.beService.stampaFiscaleRisto(scontrinoF).then(() => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = new ConfirmDialogModel(
        'Conferma emissione scontrino',
        'Procedere con le operazioni di chiusura contabile ?',
        '', false
      );

      const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.scaricaOrdine(scontrinoF.metodopagamento, 'Y', false);
        } else {
          this.resetContatori();
        }
      });
    }).catch(() => {
      this.resetContatori();
    });
  }

  private resetContatori() {
    this.listPagaAll = new Array<OrdineDettaglio>();
    this.pagaAll = false;
    this.totPagaAll = 0;
    this.listaPieSel = new Array<OrdineDettaglio>();
    this.copSel = this.pagaSel ? 1 : 0;
    this.totPieSel = 0;
    this.prezzoSelezionati = 0;
  }

  private scaricaOrdine(metodoPag: string, fiscale: string, azzera: boolean) {
    let ordineReq: OrdineReq;
    if (this.pagaAll) {
      ordineReq = this.createCloseOrdineReq(this.listPagaAll, this.tavolo.coperti, azzera ? 0 : this.totPagaAll, this.ordini.sconto);
    } else {
      this.ordini.sconto = 0;
      ordineReq = this.createCloseOrdineReq(this.listaPieSel, this.copSel, this.prezzoSelezionati, 0);
      this.listaPieSel = new Array<OrdineDettaglio>();
      this.copSel = 0;
      this.totPieSel = 0;
      this.prezzoSelezionati = 0;
    }
    const promise = new Array();
    promise.push(this.beService.closeOrdine(ordineReq, this.tavolo.nome).then(() => {
      this.tavolo.coperti -= +ordineReq.copertoPagato;
      this.totaleParziale -= +ordineReq.totale;
      this.totaleDaPagare = this.totaleParziale + (this.tavolo.coperti * this.risto.prezzoCoperto);
      if (!this.pagaAll) {
        this.getOrdini();
      }
      // tslint:disable-next-line:max-line-length
      const scaricoMagazzino = MagazzinoUtility.scaricoMagazzino(+this.risto.id, 24, fiscale, metodoPag, ordineReq.ordineDettaglios, ordineReq.totale, this.ordini.sconto);
      this.beService.scaricoMagazzinoRisto(scaricoMagazzino);
    }));
    Promise.all(promise).then(() => {
      if (this.pagaAll) {
        this.router.navigate(['/risto']);
      }
    this.ordini.sconto = 0;
  });
  }

  // tslint:disable-next-line:max-line-length
  private createScontrinoFiscale(metodo: string, listOrdDett: OrdineDettaglio[], sconto: number, coperti: number, prezzoCoperto: number): ScontrinoFiscale {
    const scontrino = {} as ScontrinoFiscale;
    scontrino.metodopagamento = metodo;
    scontrino.scontosultotale = sconto;
    const prodotti = new Array<ProdottiScFiscale>();
    if (prezzoCoperto > 0) {
      prodotti.push(this.addCopertiFiscale(coperti, prezzoCoperto));
    }
    listOrdDett.forEach(ord => {
      if ( ord.prezzo > 0 ) {
        const prodSc = {} as ProdottiScFiscale;
        const findIndex = prodotti.findIndex(item => (item.descrizione === ord.pietanza.titolo && item.prezzo === ord.prezzo));
        if (findIndex !== -1) {
          const currentProd = prodotti[findIndex];
          currentProd.quantita += ord.quantita;
        } else {
          prodSc.quantita = ord.quantita;
          prodSc.descrizione = ord.pietanza.titolo;
          prodSc.iva = 10;
          prodSc.prezzo = ord.prezzo;
          prodotti.push(prodSc);
        }
      }
    });
    scontrino.prodotti = prodotti;
    return scontrino;
  }

  addCopertiFiscale(coperti: number, prezzoCoperto: number): ProdottiScFiscale {
    const prodSc = {} as ProdottiScFiscale;
    prodSc.quantita = coperti;
    prodSc.descrizione = 'COPERTI';
    prodSc.iva = 10;
    prodSc.prezzo = prezzoCoperto;
    return prodSc;
  }

  modCopSel(op: string) {
    if (op === 'D') {
      this.copSel -= 1;
      this.totPieSel = +this.totPieSel - +this.risto.prezzoCoperto;
    } else {
      this.copSel += 1;
      this.totPieSel = +this.totPieSel + +this.risto.prezzoCoperto;
    }
  }

  checkPortata(ev: any, ord: OrdineDettaglio) {
    if (ev.target.checked) {
      this.listaPieSel.push(ord);
      this.totPieSel += +ord.prezzo;
      this.prezzoSelezionati += +ord.prezzo;
    } else {
      const findIndex = this.listaPieSel.findIndex(item => item.id === ord.id);
      this.listaPieSel.splice(findIndex, 1);
      this.totPieSel = +this.totPieSel - +ord.prezzo;
      this.prezzoSelezionati = +this.prezzoSelezionati - +ord.prezzo;
    }
  }
  private getOpFromMethod(metodo: string) {
      if (metodo === 'CS') {
        return 'Scarico magazzino';
      } else if (metodo === 'S') {
        return 'Scarico magazzino per STAFF';
      } else if (metodo === 'F') {
        return 'Scarico magazzino per FORESTERIA';
      } else if (metodo === 'C') {
        return 'Incasso fiscale in CONTANTI';
      } else {
        return 'Incasso fiscale con CARTA o BANCOMAT';
      }
    }
}

