import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tavolo-dialog',
  templateUrl: './tavolo-dialog.component.html',
  styleUrls: ['./tavolo-dialog.component.css']
})
export class TavoloDialogComponent {
  title: string;
  message1: string;
  form: FormGroup;
  currentLang: string;

  constructor(
    fb: FormBuilder,
    public dialogRef: MatDialogRef<TavoloDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: TavoloDialogModel,
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    });
    // Update view with given values
    this.title = data.title;
    this.message1 = data.message1;
    this.form = fb.group({
      des: ['',
        [
          Validators.required,
          Validators.pattern('^([0-9]{2})(|([A-B]{1}))$')
        ]
      ]
    });
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(this.form.value.des);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

export class TavoloDialogModel {
  constructor(
    public title: string,
    public message1: string,
  ) { }
}
